import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig from "src/config/ApiConfig";
import { calculateTimeLeft } from "src/utils/index";
import { injected } from "../connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
import { toast } from "react-toastify";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
  } else {
    sessionStorage.removeItem("token");
  }
};
function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const { activate, account, chainId } = useWeb3React();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState();
  const [allocatedAmount, setAllocatedAmount] = useState(false);
  const [isConnectMetamask, setisConnectMetamask] = useState(false);
  const [walletAddress, setwalletAddress] = useState(false); // eslint-disable-line
  const [lockedAmount, setLockedAmount] = useState(false);
  const [unlockedAmount, setunlockedAmount] = useState(false);
  const [totalClaimedAmount, settotalClaimedAmount] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingbalance, setloadingBalance] = useState(true);
  const [endTime, setEndtime] = useState();
  const [coinList, setCoinList] = useState([]);
  let listadCoin = [
    "Usdtbsc",
    "Bnbbsc",
    "Usdttrc20",
    "Eth",
    "Btc",
    "Usdterc20",
    "Tusd",
  ];
  const [kycData, setKycData] = useState([]);
  const [timeLeft, setTimeLeft] = useState();

  let data = {
    walletAddress,
    isConnectMetamask,
    setisConnectMetamask,
    allocatedAmount,
    loadingbalance,
    lockedAmount,
    unlockedAmount,
    totalClaimedAmount,
    userLoggedIn: isLogin,
    userData,
    kycData,
    timeLeft,
    coinList,
    setTimeLeft,
    setEndtime,
    loading,
    account,
    listadCoin,
    isCorrectNetwork: chainId === ACTIVE_NETWORK ? true : false,
    connectWallet: () => connectWallet(),
    swichNetworkHandler: () => swichNetworkHandler(),
    getLaserValue: () => getLaserValue(),
    getProfileHandler: () => getProfileHandler(),
    getCoinlistHandler: () => getCoinlistHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  const connectWallet = () => {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log("ERROR", error);
          const errorMSG = error.message; //+ ' Please install Metamask';
          toast.warn(errorMSG);
          // alert(errorMSG);
          activate(injected);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };
  const getProfileHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.myAccount, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (res.data.status === 200) {
        setKycData(res?.data?.data?.kyc?.document?.reverse()[0]);
        setUserData(res?.data?.data);
        setisConnectMetamask(
          res?.data?.data.withdrawAddress === "NA" ? true : false
        );
      }
    } catch (error) {
      if (error.response) {
        // console.log(" ----- error.response ", error.response.status);
        if (error.response.status === 404) {
          window.sessionStorage.removeItem("token");
          checkLogin();
          window.open("/", "_self");
        }
      }
    }
  };
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  const getLaserValue = async () => {
    setAllocatedAmount(0);
    setLockedAmount(0);
    setunlockedAmount(0);
    settotalClaimedAmount(0);
    // const web3 = await getWeb3Obj(default_RPC_URL);
    let arrayA = [];
    let amount = 0;
    let totalClaimedAm = 0;
    let unlockedTokenAmount = 0;

    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getParticipatedPool,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (res.data.status === 200) {
        const filteredUsers = res.data.data;
        arrayA = filteredUsers.map((listData) => listData.contractAddress);
      }
    } catch (err) {
      console.log("error fetching addresses or participated pool", err);
    }
    if (arrayA.length > 0) {
      try {
        const body = { contractAddress: arrayA };
        let resNew = await axios({
          method: "POST",
          url: ApiConfig.newAllocaltionLockFund,
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
          data: body,
        });
        if (resNew.status === 200) {
          if (resNew.data.status === 200) {
            let resultNew = resNew.data.data.result;
            amount += resultNew.amount;
            totalClaimedAm += resultNew.totalClaimedAm;
            unlockedTokenAmount += resultNew.unlockedTokenAmount;
          }
        }
      } catch (error) {
        console.log("error fetching new user allocation lock fund", error);
      }
    }
    // try {
    //   let resOld = await axios({
    //     method: "POST",
    //     url: ApiConfig.userAllocaltionLockFund,
    //     headers: {
    //       authorization: window.sessionStorage.getItem("token"),
    //     },
    //     data: {
    //       WalletAddress: WalletAddress,
    //     },
    //   });
    //   if (resOld.status === 200) {
    //     if (resOld.data.status === 200) {
    //       console.log(" ----- resNew ", resOld.data.data.result);
    //       let resultOld = resOld.data.data.result;
    //       amount += resultOld.amount;
    //       totalClaimedAm += resultOld.totalClaimedAm;
    //       unlockedTokenAmount += resultOld.unlockedTokenAmount;
    //     }
    //   }
    // } catch (err) {
    //   console.log("error fetching user allocation lock fund", err);
    // }

    let totalAvailableAmount = amount - unlockedTokenAmount;
    setAllocatedAmount(unlockedTokenAmount);
    setLockedAmount(totalAvailableAmount);
    setunlockedAmount(unlockedTokenAmount);
    settotalClaimedAmount(Number(totalClaimedAm));
    setloadingBalance(false);
  };

  const getCoinlistHandler = async () => {
    setloading(true);
    // setCoinList(listadCoin);
    let secondaryCoinList = [];
    listadCoin.forEach((element) => {
      let obj = { value: element.toString(), label: element.toUpperCase() };
      secondaryCoinList.push(obj);
    });
    setCoinList(secondaryCoinList);
    setloading(false);
    // try {
    //   const res = await axios({
    //     method: "GET",
    //     url: ApiConfig.currencieslist,
    //     headers: {
    //       authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    //     },
    //   });
    //   if (res.data.status === 200) {
    //     setloading(false);
    //     let coinsList = res.data?.data?.currencies;
    //     const matchedData = listadCoin.filter((element) =>
    //       coinsList.includes(element.toLowerCase())
    //     );
    //     let secondaryCoinList = [];
    //     matchedData.forEach((element) => {
    //       let obj = { value: element.toString(), label: element.toUpperCase() };
    //       secondaryCoinList.push(obj);
    //     });
    //     setCoinList(secondaryCoinList);
    //   }
    // } catch (err) {
    //   setloading(false);
    //   setCoinList([]);
    // }
  };
  useEffect(() => {
    if (isLogin) {
      // getLaserValue();
      getCoinlistHandler();
      getProfileHandler();
    } // eslint-disable-next-line
  }, [isLogin]);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
