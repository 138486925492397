// const url = "http://172.16.6.130:2063/api/v1"; //Local
const url = process.env.REACT_APP_BASE_URL; //live

const user = `${url}/user`;
const kyc = `${url}/kyc`;
const account = `${url}/user`;
const wallet = `${url}/wallet`;
const notification = `${url}/notification`;
const staticData = `${url}/static`;
const vesting = `${url}/vesting`;
const vestingManagement = `${wallet}/vesting-management`;

const ApiConfig = {
  // --------user-----------------
  userLogin: `${user}/userLogin`,
  userSignUp: `${user}/userSignUp`,
  verifyOTP: `${user}/verifyOTP`,
  resendemail: `${user}/resendOTP`,
  forgotPassword: `${user}/forgotPassword`,
  verifySMSCode: `${user}/verifyOTPForgotPassword`,
  verifyOTPSMS: `${user}/verifyOTPForgotPassword`,
  resetPassword: `${user}/resetPassword`,
  myAccount: `${user}/getProfile`,
  changePassword: `${user}/changePassword`,

  savekycdetails: `${kyc}/submitKyc`,
  getkycdetails: `${kyc}/getKycDetail`,

  // ------------wallet---------------
  orderhistorylist: `${wallet}/transactions`,
  withdraw: `${wallet}/wallet/withdraw`,
  getcoindetails: `${wallet}/coinDetail`,
  buytoken: `${wallet}/buyPlan`,
  getconvertedamount: `${vesting}/getConvertedAmount`,
  getsalelist: `${vesting}/getSaleList`,
  vestingPlans: `${vesting}/vestingPlans`,
  getParticipatedPool: `${vesting}/participatedPlans`,
  userAllocaltionLockFund: `${wallet}/vesting-management/userAllocaltionLockFund`,
  newAllocaltionLockFund: `${wallet}/getLockFund`,
  getLockFund: `${wallet}/getLockFund`,
  claimHistory: `${wallet}/claimHistory`,

  // ------account---
  googleauth: `${account}/googleAuth`,
  VerifyGoogleOtp: `${account}/verifyGoogleAuth`,
  DisableGoogle: `${account}/disableAuth`,
  withdrawAddress: `${account}/withdrawAddress`,

  verifygoogleauth: `${account}/verifyGoogleAuth`,

  // ---------notification--------
  getNotificationList: `${notification}/listNotification`,
  removeAllNotification: `${notification}/removeAllNotification`,

  // -----------staticmanagement---------
  contactUs: `${staticData}/submitQuery`,
  getstaticpage: `${staticData}/getStatic`,
  academyList: `${staticData}/academyList`,
  viewAcademy: `${staticData}/viewAcademy`,
  // -----------Vestingmanagement---------
  withdrawClaimablAmount: `${vestingManagement}/withdraw-claimable-amount`,
};

export default ApiConfig;
